import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import ResourceTable from '../../components/ResourceTable/ResourceTable';

class CeremonyManual extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allCeremonyManualListJson;
    const mappedResources = resources.map(resource => resource.node);
    return (
      <Layout>
        <Helmet
          title="VEYM | Ceremony Manual"
          meta={[
            {
              name: 'description',
              content: 'Ceremony Manual for all ceremonial proceedings in VEYM',
            },
          ]}
        />
        <PageHeading>Ceremony Manual</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Ceremony Manual</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <br />
          <h5>Nghi Thức</h5>
          <br />
          <p>
            Kính thưa quý Tuyên Uý, Trợ Uý, Trợ Tá, quý Huynh Trưởng cùng tất cả
            quý thành viên trong Phong Trào Thiếu Nhi Thánh Thể Việt Nam Tại Hoa
            Kỳ,
          </p>
          <p>
            Nhằm mục đích thống nhất và giúp đỡ các Trưởng đang điều hành các
            Đoàn Thiếu Nhi Thánh Thể Việt Nam Tại Hoa Kỳ về các Nghi Thức thăng
            cấp, tuyên thệ, ra mắt Đoàn, trao khăn, trao quyền chỉ huy, chuyển
            Ngành… Phong Trào Thiếu Nhi Thánh Thể Việt Nam Tại Hoa Kỳ cho phát
            hành cuốn Nghi Thức Tập II.
          </p>
          <p>
            Các Nghi Thức trong cuốn Nghi Thức Tập II này đã được bắt đầu từ năm
            1996 và được cải biến qua nhiều lần thử nghiệm dựa theo các mẫu đã
            có sẵn trước năm 1975. Đây là những kết quả được tóm lược và nghiên
            cứu trong các tài liệu của Phong Trào đang lưu hành trên toàn quốc
            Hoa Kỳ. Chắc hẳn tập Nghi Thức II này chưa được đầy đủ theo những
            nhu cầu và hoàn cảnh mới hiện nay, xin tất cả quý thành viên cùng
            đóng góp thêm để phục vụ Phong Trào ngày một hoàn hảo.
          </p>
          <p>
            Với tư cách là Tổng Tuyên Uý PT/TNTT/VN/HK, tôi xin được hân hạnh
            giới thiệu cuốn Nghi Thức Tập II này đến tất cả quý thành viên trong
            Phong Trào Thiếu Nhi Thánh Thể Việt Nam Tại Hoa Kỳ để áp dụng trong
            vòng hai (2) năm. Nếu sau hai năm, chúng ta không cần tu bổ nữa, thì
            tập Nghi Thức này sẽ trở thành tài liệu chính thức của Phong Trào.
          </p>
          <p>Atlanta, Georgia, ngày 14 tháng 4 năm 2012</p>
          <p>
            Linh mục Phanxicô Xaviê Trần Quốc Tuấn
            <br />
            Tổng Tuyên Úy
            <br />
            Chủ tịch Hội Đồng Lãnh Đạo PT/TNTT/VN/HK
          </p>
        </Container>
        <br />
        <Container>
          <ResourceTable resources={mappedResources} />
        </Container>
      </Layout>
    );
  }
}

export default CeremonyManual;

export const pageQuery = graphql`
  query CeremonyManualQuery {
    allCeremonyManualListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Ceremony Manual',
  category: 'resources',
  path: '/resources/ceremonymanual',
  description: 'Ceremony Manual',
  tags: 'resources, ceremony manual, nghi thuc, hang ',
};
